body {
	font-family: sans-serif;
	/* background-color: #eeeeee; */
}

.file-upload {
	/* background-color: #ffffff; */
	/* width: 600px; */
	margin: 0 auto;
	padding: 20px;
}

.file-upload-btn {
	width: 100%;
	margin: 0;
	color: #fff;
	background: #1fb264;
	border: none;
	padding: 10px;

	border-bottom: 3px solid #15824b;
	border-radius: 15px;
	transition: all 0.2s ease;
	outline: none;
	text-transform: uppercase;
	font-weight: 700;
}

.file-upload-btn:hover {
	/* background: #1aa059; */
	color: #fff;
	transition: all 0.2s ease;
	cursor: pointer;
}

.file-upload-btn:active {
	border: 0;
	transition: all 0.2s ease;
}

.file-upload-content {
	display: none;
	text-align: center;
}

.file-upload-input {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	outline: none;
	opacity: 0;
	cursor: pointer;
}

.image-upload-wrap {
	/* border: 1px dashed #1fb264; */
	position: relative;
	border-radius: 15px;
}

.image-dropping,
.image-upload-wrap:hover {
	/* background-color: #0468fc; */
	/* border: 1px dashed #0468fc; */
	color: #0468fc important!;
}

.image-title-wrap {
	padding: 0 15px 15px 15px;
	color: #222;
}

.drag-text {
	text-align: center;
}

.drag-text h3 {
	font-weight: 100;
	text-transform: uppercase;
	color: #15824b;
	padding: 60px 0;
	font-size: 17px;
	margin: 10px;
}

.file-upload-image {
	max-height: 200px;
	max-width: 200px;
	margin: auto;
	padding: 20px;
}

.remove-image {
	width: 200px;
	margin: 0;
	color: #fff;
	background: #cd4535;
	border: none;
	padding: 10px;
	border-radius: 4px;
	border-bottom: 4px solid #b02818;
	transition: all 0.2s ease;
	outline: none;
	text-transform: uppercase;
	font-weight: 700;
}

.remove-image:hover {
	background: #c13b2a;
	color: #ffffff;
	transition: all 0.2s ease;
	cursor: pointer;
}

.remove-image:active {
	border: 0;
	transition: all 0.2s ease;
}
